<template>
<div>
  <header>
    <div class="top-header">
        <div class="container">
            <div class="row ">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mobile-hide">
                    <ul>
                        <li><a><i class="fas fa-envelope"></i> {{ email }}</a></li>
                        <li><a><i class="fas fa-phone-alt"></i> {{ phone }}</a></li>
                    </ul>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <p v-html="tagline"></p>
                    <p class="google-Translation"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="main-header">
        <div class="container">
            <div class="row cart-Hamburger">
              <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6"
                   :class="{'mobile-hide': !(hostName == envAssignHostName && open_type == 'agent' && !image)}"
                   v-if="!(hostName == envAssignHostName && (open_type == 'group' || open_type == 'user'))">
                <div class="store-logo" @click="homePage"  v-if="(hostName == envAssignHostName && open_type == 'agent') || hostName !== envAssignHostName">
                  <img :src="logo" alt="Image" class="img-fluid">
                </div>
                <div class="store-logo" @click="homePage"
                     v-else-if="(hostName == envAssignHostName && open_type == 'group' && logo && !image)|| hostName !== envAssignHostName">
                  <img :src="logo" alt="Image">
                </div>
                <div class="store-logo" @click="homePage" v-else>
                  <img :src="logo" alt="Image">
                </div>
              </div>
              <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 "
                   v-if="!(hostName == envAssignHostName && open_type == 'agent' && !image)" >
                <div class="agent-logo" @click="homePage"
                     v-if="(hostName == envAssignHostName && open_type == 'group') && image">
                  <img :src="image" alt="Image" class="img-fluid ms-2">
                </div>
                <div class="agent-logo  left-border" v-else-if="(hostName == envAssignHostName && open_type == 'agent')" @click="homePage">
                  <img :src="image" alt="Image" class="img-fluid ms-2 mobile-hide">
                  <img :src="logo" alt="Image" class="img-fluid ms-2 mobile-display">
                </div>
                <div  class="agent-logo"  v-else @click="homePage">
                  <img :src="image" alt="Image" v-if="image">
                  <img :src="logo" alt="Image" v-if="!image && logo && checkRoutePath()">
                </div>
              </div>
                <div class="col-xxl-7 col-xl-7 col-lg-5 col-md-5 col-6 vertical-center">
                    <div class="cart-btn" id="cart-hover">
                      <router-link to="/cart" class="tooltip-hover">
                        <div class="cartIcon"  translate="no">
                          <img :src="cartLogo" alt="">
                          <span class="badge">{{ cartItems }}</span>
                        </div>
                        <span class="headerCartprice" translate="no">{{amountCart}}</span>
                        <span class="tooltiptext">View Cart</span>
                      </router-link>
                    </div>
<!--                    <b-popover target="cart-hover" triggers="hover" placement="bottom">
                       View Cart
                    </b-popover>-->
                    <div class="about-btn mobile-hide" v-if="hostName !== envAssignHostName">
                        <router-link to="" v-b-modal.aboutUser>About {{ display_name }}</router-link>
                    </div>
                    <div class="about-btn rc-btn mobile-hide" v-if="cartItems>0 && $store.state.hasRecommendedPlans && checkForQuote()">
                      <router-link :to="{  name: 'RecommendList',  params: { name: 'cart' },}" >
                        <img src="../assets/images/recommend-character.svg" alt="" class="img-fluid">
                        <span>View Your <br>Recommended Products</span>
                      </router-link>
                    </div>
                    <div class="corenrollApp-list" v-if="siteUrl === 'false'">
                        <b-dropdown class="dashboard-icons">
                            <template #button-content>
                                <img src="../assets/images/icons/app-icon.svg" id="dash-icon" />
                            </template>
                            <template #item-content>

                            </template>
                            <b-dropdown-item href="javascript:void(0)">
                                <div class="row">
                                    <a :href="menuLink.routeToAdmin" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/admin.svg" alt="" />
                                        <span class="d-block text-center">Admin</span>
                                    </a>
                                    <a :href="menuLink.routeToMessageCenter" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/message-center.svg" alt="" />
                                        <span class="d-block text-center">Message center</span>
                                    </a>
                                    <a :href="menuLink.routeToFileManagement" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/file-management.svg" alt="" />
                                        <span class="d-block text-center">File Management</span>
                                    </a>
                                    <a :href="menuLink.routeToRepDashboard" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Dashboard-REP.svg" alt="" />
                                        <span class="d-block text-center">Dashboard (Rep)</span>
                                    </a>
                                    <a :href="menuLink.routeToMemberDashboard" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Dashboard-Member.svg" alt="" />
                                        <span class="d-block text-center">Dashboard (Member)</span>
                                    </a>
                                    <a :href="menuLink.routeToGroupDashboard" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Dashboard-group.svg" alt="" />
                                        <span class="d-block text-center">Dashboard (Group)</span>
                                    </a>
                                    <a :href="menuLink.routeToTicketManagement" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/ticket-management.svg" alt="" />
                                        <span class="d-block text-center">Ticket <br>Management</span>
                                    </a>
                                    <a :href="menuLink.routeToSos" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/SSO.svg" alt="" />
                                        <span class="d-block text-center">SSO</span>
                                    </a>
                                    <hr>
                                    <h3>Registrations</h3>
                                    <a :href="menuLink.routeToRepRegistration" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Da-Reg.svg" alt="" />
                                        <span class="d-block text-center">Rep<br>Registration</span>
                                    </a>
                                    <a :href="menuLink.routeToMemberRegistration" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Dm-Reg.svg" alt="" />
                                        <span class="d-block text-center">Member<br>Registration</span>
                                    </a>
                                    <a :href="menuLink.routeToGroupRegistration" class="col-4 dashIcon-block" target="_blank">
                                        <img src="../assets/images/icons/Dg-Reg.svg" alt="" />
                                        <span class="d-block text-center">Group<br>Registration</span>
                                    </a>

                                </div>
                            </b-dropdown-item>
                        </b-dropdown>

                    </div>
                     <Bubble right class="mobile-display" :closeOnNavigation="true">
                            <div v-if="hostName === envAssignHostName && open_type !== 'agent' && open_type !== 'group'">
                              <img :src="bexMobileLogo" alt="" class="img-fluid">
                            </div>
                           <div class="agent-logo" v-else-if="(hostName == envAssignHostName && open_type == 'agent')">
                             <img :src="logo" alt="Image" class="img-fluid">
                           </div>
                            <div v-else>
                              <img :src="bexMobileLogo" alt="" class="img-fluid"
                                   v-if="hostName === envAssignHostName && open_type == 'group' && !image">
                              <img :src="image" alt="" class="img-fluid" v-else>
                            </div>
                            <div class="agent-mobile-detail text-center">
                                <ul>
                                    <li><a href="javascript:void(0);"><i class="fas fa-envelope"></i> {{ email }}</a></li>
                                    <li><a href="javascript:void(0);"><i class="fas fa-phone-alt"></i> {{ phone }}</a></li>
                                    <li><a href="javascript:void(0);" v-b-modal.aboutUser>{{ display_name }}</a></li>
                                </ul>
                            </div>
                            <hr>
                     <ul class="menu-list" v-if="portal_id">
                       <li v-for="(menu,index) in menuArray" v-bind:key="index">
                         <a href="javascript:void(0)" class="text-uppercase" @click="menuItem(menu)">{{ menu }}</a>
                       </li>
                     </ul>
                     <div v-for="(menu,index) in menuArray" v-bind:key="index" v-else>
                        <a href="javascript:void(0)">
                            <span @click="menuItem(menu)" class="text-uppercase">{{ menu }}</span>
                        </a>
                      </div>
                        </Bubble>
                </div>
            </div>
        </div>
    </div>
    <div class="nav-menu mobile-hide" v-if="portal_id">
        <div class="container">
            <ul>
                <!--                  <li v-for="(menu,index) in menus" v-bind:key="index">
                    <a href="javascript:void(0)" @click="menuItem(menu)">{{ menu }}</a>
                  </li>-->
                <li v-if="hostName === envAssignHostName">
                  <a href="javascript:void(0)" class="menu" @click="routeToGoEnrollHome"> Home</a>
                </li>
                <li v-for="(menu,index) in menuArray" v-bind:key="index">
                    <a href="javascript:void(0)" class="menu" :class="{'active-header': menu === menuSelectedItem}" @click="menuItem(menu)"> {{menu}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="vertical-line" v-if="!portal_id"></div>
    <!-- User About Pop up -->
    <b-modal id="aboutUser" centered size="md" v-model="show">
        <template #modal-header>
            <div class="popUp-header">
                <h4>About {{ display_name }}</h4>

                <b-button variant="close-btn" @click="show=false">
                    <i class="fas fa-times"></i>
                </b-button>
            </div>
        </template>
        <b-container fluid>
            <div class="aboutUser-details">
                <p v-html="about_content"></p>
            </div>
        </b-container>
    </b-modal>
</header>
  <div>
    <banner-text v-if="siteUrl == 'true'"></banner-text>
  </div>
</div>
</template>

<script>
import { Bubble } from 'vue-burger-menu'
import BannerText from "../components/BannerText.vue";
import axios from "axios";
export default {
  name: 'SiteHeader',
  components: {
      Bubble,
    'banner-text': BannerText,
  },
  data() {
    return {
      show: false,
      logo: 'https://corenroll.com/biz_image.php?file=test_1648564270_BENAdvance-logo.png',
      image: '',
      cartLogo: require('../assets/images/cart.svg'),
      phone: 'Loading..',
      email: 'Loading..',
      tagline: '',
      about_name: '...',
      display_name: '...',
      cartItems: 0,
      about_popup: false,
      about_content: 'Not Available',
      eprocess: '',
      open_type: '',
      group_id: '',
      portal_id: '',
      agent_id: '',
      menuList: [],
      /* menus: {
              dental: '',
              vision: '',
              medical: '',
              limitedmed: '',
              term_life: '',
              supplemental: '',
              lifestyle: '',
              rx: '',
              di: '',
              critical: '',
              accident: '',
              hospital: ''
            },*/
      menus: {},
      menuName: '',
      amountCart: '',
      menuArray: [],
      isActive: false,
      menuSelectedItem: '',
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      },
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME,
      bexMobileLogo: 'https://corenroll.com/biz_image.php?file=test_1648564270_BENAdvance-logo.png',
      link:null,
      landingName: '',
      categoryOrder: [],
      redirectionType: ''
    }
  },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.open_type = window.localStorage.getItem('open_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.referral = window.localStorage.getItem('referral');
    app.fromSite = window.localStorage.getItem('fromSite');
    app.temp_id = window.localStorage.getItem('tempId');
    app.portal_id = window.localStorage.getItem('portal_id');
    app.landingName = window.localStorage.getItem('landingName');
    app.redirectionType = window.localStorage.getItem('redirectionType');
    app.cartItems = localStorage.getItem('cartCount') ? localStorage.getItem('cartCount') : app.cartItems;
    localStorage.getItem('menuSelectedItem') ? app.menuSelectedItem = localStorage.getItem('menuSelectedItem') : '';
    console.log('eprocess of landing page', app.eprocess);
    if(app.group_id && app.portal_id){
    app.getMenus();
    }
    app.cartAmount(window.localStorage.getItem('tempId'));
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
      app.getGroupAboutInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
      app.getAgentAboutInfo();
    }
    app.$root.$on('cartData', (id) => {
      app.cartCount(id);
      app.cartAmount(id);
    });
    app.$root.$on('cartCountStatus', (id) => {
      console.log('***** inside cart count status*****')
      console.log(id)
      app.cartItems = localStorage.getItem('cartCount') ? localStorage.getItem('cartCount') : 0
    });
    this.$root.$on('menuSelected', (menu) => {
      console.log(menu)
      app.menuSelectedItem = ''
    });
    app.fetchCatchInfo();
  },
  methods: {
    homePage: function () {
      if(this.link){
        window.open(this.link, '_blank');
      }else{
      if (this.open_type !== null && typeof (this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof (this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid=' + this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    }
    },
    getMenus: function () {
      console.log('******* getMenus *********')
      let app = this;
      let url;
      // delete app.menus.di;
      let portal = window.localStorage.getItem('portal_id');
      app.group_id = window.localStorage.getItem('group_id');
      // let group = window.localStorage.getItem('group_id');
      if (app.redirectionType == 'direct-continue') {
        url = process.env.VUE_APP_API_BASE + `/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}&enrollment_id=${app.temp_id}`;
      } else if (app.eprocess === 'rep') {
        if (portal !== null) {
          url = process.env.VUE_APP_API_BASE + `/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        } else {
          url = process.env.VUE_APP_API_BASE + `/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        }
        // url = process.env.VUE_APP_API_BASE+`/get-agent-menu?agent_id=${app.agent_id}`;
      } else {
        let fromSite = window.localStorage.getItem('fromSite')
        if (fromSite == 'elevate') {
          url = process.env.VUE_APP_API_BASE + `/get-group-menu?group_id=${app.group_id}&from_site=${fromSite}`;
        } else {
          if (portal !== null) {
            url = process.env.VUE_APP_API_BASE + `/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
          } else {
            url = process.env.VUE_APP_API_BASE + `/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
          }
          // url = process.env.VUE_APP_API_BASE+`/get-group-menu?group_id=${app.group_id}`;
        }
      }
      axios.get(url).then(function (response) {
        if (response.data.status === 'success') {
          console.log('****** inside getMenus success ******')
          response.data.data.forEach((item) => {
            console.log('****** inside getMenus for loop ******', item)
            // Replacing underscore with space.
            if (item == 'term_life') {
              item = item.split('_').join(' ');
            }
            app.menuArray.push(item);
            if (item == 'dental') {
              console.log('****** inside getMenus for loop  dental ******', item)
              app.menus.dental = 'Dental';
            }
            if (item == 'vision') {
              app.menus.vision = 'Vision';
            }
            if (item == 'medical') {
              app.menus.medical = 'Medical';
            }
            if (item == 'limitedmed') {
              app.menus.limitedmed = 'Limited Med';
            }
            if (item == 'term_life') {
              app.menus.term_life = 'Term Life';
            }
            if (item == 'supplemental') {
              app.menus.supplemental = 'Supplemental';
            }
            if (item == 'lifestyle') {
              app.menus.lifestyle = 'Lifestyle';
            }
            if (item == 'rx') {
              app.menus.rx = 'RX';
            }
            if (item == 'di') {
              app.menus.di = 'Disability Income';
            }
            if (item == 'critical') {
              app.menus.critical = 'Critical Illness'
            }
            if (item == 'accident') {
              app.menus.accident = 'Accident';
            }
            if (item == 'hospital') {
              app.menus.hospital = 'Hospital';
            }
            if (item == 'pet') {
              app.menus.pet = 'PET';
            }
            if (item == 'bundles') {
              app.menus.pet = 'BUNDLES';
            }
          });
          app.categoryOrder = JSON.stringify(response.data.category_order)
          window.localStorage.setItem("categoryOrder",  app.categoryOrder)
          app.menuList.push(app.menus)
        }
      })
          .catch(function (error) {
            console.log(error);
          });
    },
    menuItem: function (val) {
      let app = this
      /****** Please don`t delete this code it may be used later
       /*  if (val === 'Dental') {
                app.menuName = 'dental';
              }
       if (val === 'Vision') {
                app.menuName ='vision';
              }
       if (val === 'Medical') {
                app.menuName = 'medical';
              }
       if (val === 'Limited Med') {
                app.menuName = 'limitedmed';
              }
       if (val === 'Term Life') {
                app.menuName = 'term_life';
              }
       if (val === 'Supplemental') {
                app.menuName = 'supplemental';
              }
       if (val === 'Lifestyle') {
                app.menuName = 'lifestyle';
              }
       if (val === 'RX') {
                app.menuName = 'rx';
              }
       if (val === 'Disability Income') {
                app.menuName = 'di';
              }
       if (val === 'Critical Illness') {
                app.menuName  = 'critical'
              }
       if (val === 'Accident') {
                app.menuName  = 'accident';
              }
       if (val === 'Hospital') {
                app.menuName  = 'hospital';
              }
       if (val === 'PET') {
                app.menuName  = 'pet';
              } *************/
      if (val === 'dental') {
        app.menuName = 'dental';
      }
      if (val === 'vision') {
        app.menuName = 'vision';
      }
      if (val === 'medical') {
        app.menuName = 'medical';
      }
      if (val === 'limitedmed') {
        app.menuName = 'limitedmed';
      }
      if (val === 'term life') {
        app.menuName = 'term_life';
      }
      if (val === 'supplemental') {
        app.menuName = 'supplemental';
      }
      if (val === 'lifestyle') {
        app.menuName = 'lifestyle';
      }
      if (val === 'rx') {
        app.menuName = 'rx';
      }
      if (val === 'di') {
        app.menuName = 'di';
      }
      if (val === 'critical') {
        app.menuName = 'critical'
      }
      if (val === 'accident') {
        app.menuName = 'accident';
      }
      if (val === 'hospital') {
        app.menuName = 'hospital';
      }
      if (val === 'pet') {
        app.menuName = 'pet';
      }
      if (val === 'bundles') {
        app.menuName = 'bundles';
      }
      app.menuSelectedItem = val;
      localStorage.setItem('menuSelectedItem', val)
      this.$root.$emit('menuData', app.menuName);
      this.$router.push({
        name: 'plans',
        query: {
          type: app.menuName
        }
      })
      if ((app.eprocess == 'applicant' && app.open_type == 'group') || (app.eprocess == 'applicant' && app.open_type == 'agent')) {
        this.$router.push({
          path: `/${app.landingName}/plans`,
          query: {
            type: app.menuName
          }
        })
      } else {
        this.$router.push({
          name: 'plans',
          query: {
            type: app.menuName
          }
        })
      }
    },
    logout: function () {
      localStorage.clear();
      this.$router.push('/');
    },
    cartPage: function () {
      this.$router.push({
        name: 'cart'
      });
    },
    cartCount: function (id) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-cart-count', {
        params: {
          enrollment_id: id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.cartItems = response.data.data.result;
              localStorage.setItem('cartCount', app.cartItems)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentHeaderInfo: function () {
      let app = this;
      app.landing = {};
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            window.localStorage.setItem('phone',app.phone);
            app.landing['phone'] = app.phone;
            app.email = response.data.data.display_email;
            app.landing['email'] = app.email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            if(response.data.data.appointment_){
            window.localStorage.setItem('appointmentInfo',JSON.stringify(response.data.data.appointment_));
            }
            window.localStorage.setItem('display_name', response.data.data.display_name);
            app.link=response.data.data.image_link;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
            let repInfo = {};
            repInfo['name'] = response.data.data.display_name;
            repInfo['email'] = response.data.data.display_email;
            repInfo['phoneNumber'] = response.data.data.display_phone;
            window.localStorage.setItem("repInfo", JSON.stringify(repInfo));
            app.$bus.$emit('getRepInfoLead');
            app.$root.$emit("infoLanding", app.landing);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-about-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.agent_bio;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            window.localStorage.setItem('phone',app.phone);
            app.email = response.data.data.display_email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            if(response.data.data.appointment_){
            window.localStorage.setItem('appointmentInfo',JSON.stringify(response.data.data.appointment_));
            }
            window.localStorage.setItem('display_name', response.data.data.display_name);
            app.link=response.data.data.image_link;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
            let repInfo = {};
            repInfo['name'] = response.data.data.display_name;
            repInfo['email'] = response.data.data.display_email;
            repInfo['phoneNumber'] = response.data.data.display_phone;
            window.localStorage.setItem("repInfo", JSON.stringify(repInfo));
            app.$bus.$emit('getRepInfoLead');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-about-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.gweb_aboutus;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            console.log('getCatchColor12', getCatchColor)
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            console.log('newObj', newObj)
            this.logo = newObj["VUE_APP_DEFAULT_LOGO"]
            // this.image = newObj["VUE_APP_DEFAULT_LOGO"]
            this.cartLogo = newObj["VUE_APP_CART_LOGO"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    },
    cartAmount:function(id){
        let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-cart-amount', {
        params: {
          enrollment_id: id,
        },
      })
          .then(function (response) {
            app.amountCart = response.data.data.result;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
     routeToGoEnrollHome () {
      let app = this
       if ((this.eprocess == 'applicant' && this.open_type == 'group') || (this.eprocess == 'applicant' && this.open_type == 'agent')) {
         this.$router.push(`/${this.landingName}`)
       } else {
         app.$router.push('/');
       }
    },
    checkRoutePath () {
      let app = this
      if (app.$route.path == '/continue-enrollment') {
        return false
      } else {
        return true
      }
    },
    checkForQuote () {
      let app = this
      if (app.redirectionType == 'direct-continue') {
        return false;
      } else if (app.redirectionType == 'save-cart') {
        return false;
      } else {
        return true
      }
    }
  },
    // Event bus library build in method listener object
    listener: {
      getMenuList () {
        this.menuArray = []
        this.getMenus()
        this.portal_id = window.localStorage.getItem('portal_id');
      },
      'FromDirect'(){
        let app = this;
        app.group_id = window.localStorage.getItem('group_id');
        app.portal_id = window.localStorage.getItem('portal_id');
        app.agent_id = window.localStorage.getItem('agent_id');
        if (app.open_type === 'group' || app.eprocess === 'group') {
          app.getGroupHeaderInfo();
          app.getGroupAboutInfo();
        } else {
          app.getAgentHeaderInfo();
          app.getAgentAboutInfo();
        }
      },
      'externalsite'(){
        let app = this;
        app.group_id = window.localStorage.getItem('group_id');
        app.agent_id = window.localStorage.getItem('agent_id');
        this.portal_id = window.localStorage.getItem('portal_id');
        if (app.open_type === 'group' || app.eprocess === 'group') {
          app.getGroupHeaderInfo();
          app.getGroupAboutInfo();
        } else {
          //open landing pages
          app.getAgentHeaderInfo();
          app.getAgentAboutInfo();
        }
      }
    }
}
</script>

<style lang="scss"></style>
